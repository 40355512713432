<template>
    <div></div>
</template>

<script>
export default {
    methods: {
        async loadCurrentUser() {
            let response = await this.$axios.get('/api/Account/GetCurrentUser');
            if (response.data) {
                this.$store.dispatch('setUser', response.data);

                await this.loadDataDefinitions();
                await this.loadCampuses();

                if (this.$route.query.redirect) {
                    this.$router.replace(this.$route.query.redirect);
                }
                else {
                    this.$router.replace('/');
                }
            }
            else {
                this.$router.replace({ path: '/login', query: { redirect: this.$route.query.redirect } });
            }
        },
        async loadDataDefinitions() {
            let response = await this.$axios.get('/api/DataDefinition/GetDataDefinitions');
            this.$store.dispatch('setDataDefinitions', response.data);
        },
        async loadCampuses() {
            let response = await this.$axios.get('/api/Organization/GetCampusesByUser');
            let campuses = response.data;
            this.$store.dispatch('setCampuses', campuses);

            if (campuses.length != 0) {
                let localStorageCampuses = campuses.filter(p => p.id == localStorage.campusId);
                if (localStorageCampuses.length == 0) {
                    this.$store.dispatch('setCampusId', campuses[0].id);
                }
                else {
                    this.$store.dispatch('setCampusId', localStorageCampuses[0].id);
                }
            }
        },
    },
    created() {
        if (!localStorage.token) {
            this.$router.replace({ path: '/login', query: { redirect: this.$route.query.redirect } });
            return;
        }

        this.loadCurrentUser();
    },
};
</script>