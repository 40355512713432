<template>
    <div class="vh-100 vw-100" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"
        style="background-position: center; background-size: cover;">
        <div class="login-panel">
            <div class="text-center">
                <img src="@/assets/logo.png" style="width: 80px; height: 80px;" />
            </div>
            <div class="text-center">
                <h3>{{ $store.state.applicationName }}</h3>
            </div>
            <div class="mt-4">
                <el-form>
                    <el-form-item>
                        <el-input v-model="userName" prefix-icon="el-icon-user" placeholder="用户名"
                            @keyup.enter.native="login" />
                    </el-form-item>
                    <el-form-item>
                        <el-input type="password" v-model="password" prefix-icon="el-icon-key" placeholder="密码"
                            @keyup.enter.native="login" />
                    </el-form-item>
                </el-form>
            </div>
            <div>
                <el-button type="primary" round class="w-100 mt-4" @click="login">登录</el-button>
            </div>
            <div class="text-info mt-4 text-center">{{ $store.state.company }}</div>
        </div>
    </div>
</template>

<script>
import background from '@/assets/background.jpg';

export default {
    data() {
        return {
            backgroundImage: background,
            userName: null,
            password: null,
        };
    },
    methods: {
        async login() {
            let response = await this.$axios.post('/api/Account/Login', {
                userName: this.userName,
                password: this.password,
            });
            localStorage.token = response.data;
            if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect).catch(() => { });
            }
            else {
                this.$router.push('/').catch(() => { });
            }
        },
    },
    created() {
        document.title = '登录 - ' + this.$store.state.applicationName;
    },
};
</script>

<style>
.login-panel {
    background-color: white;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    padding: 3rem;
}

@media (min-width: 576px) {
    .login-panel {
        background-color: rgba(255, 255, 255, 0.75);
        height: auto !important;
        max-width: 400px;
        position: relative;
        top: 10vh;
        border-radius: 0.5rem;
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(0.5rem);
    }
}
</style>