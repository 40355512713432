import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        applicationName: '教育服务平台',
        company: '新疆博思纵横投资有限公司',
        user: null,
        dataDefinitions: {},
        campuses: [],
        campusId: null,
        defaultMapCenter: [87.614527, 43.802915],
    },
    getters: {
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setDataDefinitions(state, dataDefinitions) {
            state.dataDefinitions = dataDefinitions;
        },
        setCampuses(state, campuses) {
            state.campuses = campuses;
        },
        setCampusId(state, campusId) {
            state.campusId = campusId;
        },
    },
    actions: {
        setUser(context, user) {
            context.commit('setUser', user);
        },
        setDataDefinitions(context, dataDefinitions) {
            context.commit('setDataDefinitions', dataDefinitions);
        },
        setCampuses(context, campuses) {
            context.commit('setCampuses', campuses);
        },
        setCampusId(context, campusId) {
            context.commit('setCampusId', campusId);
        },
    },
    modules: {
    },
});