<template>
    <el-dialog title="选择组织机构" :visible.sync="visible" :close-on-click-modal="false" append-to-body width="400px"
        @closed="$emit('input',false)">
        <div class="narrow-scrollbar border rounded overflow-auto" style="height: 400px;">
            <el-tree :data="organizationTreeNodes" node-key="id" highlight-current default-expand-all
                :expand-on-click-node="false" @current-change="selectOrganizationTreeNode" />
        </div>
        <template #footer>
            <el-button type="primary" @click="selectOrganization">确定</el-button>
            <el-button @click="$emit('input',false)">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
        },
        data() {
            return {
                visible: this.value,
                organizationTreeNodes: [],
                organizationId: null,
                organizationName: null,
            };
        },
        watch: {
            value(value) {
                this.visible = value;
                if (value) {
                    this.organizationId = null;
                    this.organizationName = null;
                    this.loadOrganizationTreeNodes();
                }
            },
        },
        methods: {
            async loadOrganizationTreeNodes() {
                let response = await this.$axios.get('/api/Organization/GetOrganizationTreeNodes');
                this.organizationTreeNodes = response.data;
            },
            selectOrganizationTreeNode(data) {
                this.organizationId = data.id;
                this.organizationName = data.label;
            },
            selectOrganization() {
                if (this.organizationId == null) {
                    return;
                }

                this.$emit('select', { id: this.organizationId, name: this.organizationName });
                this.$emit('input', false);
            },
        },
    };
</script>