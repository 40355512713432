<template>
    <div :class="{'shadow-sm':showShadow}" class="row no-gutters list-item" :style="{cursor:(showArrow?'pointer':'')}"
        @click="click">
        <div v-if="icon" class="col-auto d-flex align-items-center pl-2 pr-3">
            <font-awesome-icon :icon="icon" fixed-width class="text-lg" :style="{color:iconColor}" />
        </div>
        <div class="col d-flex align-items-center">
            <div>
                <div class="text-lg">
                    <slot>{{text}}</slot>
                </div>
                <div class="text-sm o-50">
                    <slot name="detail">{{detail}}</slot>
                </div>
            </div>
        </div>
        <div class="col-auto d-flex align-items-center">
            <div style="margin-top: -3px;">
                <slot name="value">{{value}}</slot>
            </div>
            <div v-if="showArrow" class="ml-1">
                <i class="el-icon-arrow-right font-weight-bold o-50"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            icon: { type: String },
            iconColor: { type: String, default: '#606266' },
            text: { type: String },
            detail: { type: String },
            value: { type: String },
            showArrow: { type: Boolean, default: false },
            showShadow: { type: Boolean, default: false },
        },
        methods: {
            click() {
                this.$emit('click');
            },
        },
    };
</script>

<style>
    .list-item {
        background-color: white;
        padding: 16px;
    }

    .list-item:hover {
        opacity: 0.75;
    }
</style>