<template>
    <el-dialog :visible.sync="visible" title="选择课程" :close-on-click-modal="false" append-to-body width="640px"
        @closed="$emit('input',false)">
        <div>
            请选择
            <span class="text-danger">{{requiredCount}}</span>
            节课：
        </div>
        <div class="narrow-scrollbar border overflow-auto mt-2" style="height: 480px;">
            <el-table :data="lessons" :show-header="false" @selection-change="lessonSelectionChange">
                <el-table-column type="selection" align="center" width="50" />
                <el-table-column prop="name" label="名称" sortable />
            </el-table>
        </div>
        <template #footer>
            <el-button type="primary" :disabled="requiredCount!=selectedLessons.length" @click="selectLessons">确定
            </el-button>
            <el-button @click="$emit('input',false)">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
            courseClassId: { type: String },
            requiredCount: { type: Number },
        },
        data() {
            return {
                visible: this.value,
                lessons: [],
                selectedLessons: [],
            };
        },
        watch: {
            value(value) {
                this.visible = value;
                if (this.visible) {
                    this.loadLessons();
                }
            },
        },
        methods: {
            async loadLessons() {
                let response = await this.$axios.get('/api/Lesson/GetLessons', {
                    params: {
                        courseClassId: this.courseClassId,
                    }
                });
                this.lessons = response.data;
            },
            lessonSelectionChange(value) {
                this.selectedLessons = value;
            },
            selectLessons() {
                this.$emit('select', this.selectedLessons);
                this.visible = false;
            },
        },
    };
</script>