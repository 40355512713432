import moment from 'moment';

function install(Vue) {
    moment.locale('zh-cn');

    Vue.prototype.$moment = moment;
    Vue.prototype.$moment.sd = (value) => {
        return value ? moment(value).format('YYYY/M/D') : '';
    };
    Vue.prototype.$moment.ld = (value) => {
        return value ? moment(value).format('YYYY年M月D日') : '';
    };
    Vue.prototype.$moment.st = (value) => {
        return value ? moment(value).format('HH:mm') : '';
    };
    Vue.prototype.$moment.lt = (value) => {
        return value ? moment(value).format('HH:mm:ss') : '';
    };
    Vue.prototype.$moment.sdt = (value) => {
        return value ? moment(value).format('YYYY/M/D HH:mm') : '';
    };
    Vue.prototype.$moment.ldt = (value) => {
        return value ? moment(value).format('YYYY年M月D日 HH:mm:ss') : '';
    };
    Vue.prototype.$moment.sdFormatter = (row, column, cellValue) => {
        return moment.sd(cellValue);
    };
    Vue.prototype.$moment.ldFormatter = (row, column, cellValue) => {
        return moment.ld(cellValue);
    };
    Vue.prototype.$moment.stFormatter = (row, column, cellValue) => {
        return moment.st(cellValue);
    };
    Vue.prototype.$moment.ltFormatter = (row, column, cellValue) => {
        return moment.lt(cellValue);
    };
    Vue.prototype.$moment.sdtFormatter = (row, column, cellValue) => {
        return moment.sdt(cellValue);
    };
    Vue.prototype.$moment.ldtFormatter = (row, column, cellValue) => {
        return moment.ldt(cellValue);
    };
}

export default { install };