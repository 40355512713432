function download(url, fileName = '') {
    let a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

function install(Vue) {
    Vue.prototype.$download = download;
}

export default { install };