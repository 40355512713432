import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

function install(Vue) {
    library.add(fas, far, fab);

    Vue.component(FontAwesomeIcon.name, FontAwesomeIcon);
    Vue.component(FontAwesomeLayers.name, FontAwesomeLayers);
    Vue.component(FontAwesomeLayersText.name, FontAwesomeLayersText);
}

export default { install };