<template>
    <el-dialog title="选择知识点" :visible.sync="visible" :close-on-click-modal="false" append-to-body width="480px"
        @closed="$emit('input',false)">
        <div class="narrow-scrollbar border rounded overflow-auto" style="height: 480px;">
            <el-tree :data="knowledgePointTreeNodes" node-key="id" highlight-current :expand-on-click-node="false"
                @current-change="selectKnowledgePointTreeNode" />
        </div>
        <template #footer>
            <el-button type="primary" @click="selectKnowledgePoint">确定</el-button>
            <el-button @click="$emit('input',false)">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
            grade: { type: Number },
            subject: { type: Number },
        },
        data() {
            return {
                visible: this.value,
                knowledgePointTreeNodes: [],
                knowledgePointId: null,
                knowledgePointName: null,
            };
        },
        watch: {
            value(value) {
                this.visible = value;
                if (value) {
                    this.knowledgePointId = null;
                    this.knowledgePointName = null;
                    this.loadKnowledgePointTreeNodes();
                }
            },
        },
        methods: {
            async loadKnowledgePointTreeNodes() {
                let response = await this.$axios.get('/api/KnowledgePoint/GetKnowledgePointTreeNodes', {
                    params: {
                        grade: this.grade,
                        subject: this.subject,
                    }
                });
                this.knowledgePointTreeNodes = response.data;
            },
            selectKnowledgePointTreeNode(data) {
                this.knowledgePointId = data.id;
                this.knowledgePointName = data.label;
            },
            selectKnowledgePoint() {
                if (this.knowledgePointId == null) {
                    return;
                }

                this.$emit('select', { id: this.knowledgePointId, name: this.knowledgePointName });
                this.$emit('input', false);
            },
        },
    };
</script>