<template>
    <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false" append-to-body width="480px"
        @closed="$emit('input',false)">
        <el-form>
            <div v-if="description" class="text-info mb-2">{{description}}</div>
            <el-form-item label="文件">
                <file-uploader v-model="file" placeholder="文件" />
            </el-form-item>
            <div v-if="template">
                <a :href="template" download>下载模板</a>
            </div>
        </el-form>
        <template #footer>
            <el-button type="primary" @click="importData">确定</el-button>
            <el-button @click="$emit('input',false)">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
            action: { type: String },
            data: { type: Object, default() { return {}; } },
            title: { type: String, default: '导入' },
            description: { type: String },
            template: { type: String },
        },
        data() {
            return {
                visible: this.value,
                file: null,
            };
        },
        watch: {
            value(value) {
                this.visible = value;
                if (value) {
                    this.file = null;
                }
            },
        },
        methods: {
            async importData() {
                if (!this.file) {
                    return;
                }

                try {
                    let data = {};
                    Object.assign(data, this.data);
                    data.file = this.file;

                    await this.$axios.post(this.action, data);
                    this.$emit('success', this.file);
                    this.$emit('input', false);
                }
                finally {
                    this.file = null;
                }
            },
        },
    };
</script>