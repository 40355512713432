<template>
    <button type="button" :class="{'is-disabled':disabled}" class="menu-item" @click="click">
        <span v-if="!hideIcon" class="d-inline-block mr-2" style="width: 20px;">
            <font-awesome-icon v-if="icon" :icon="icon" fixed-width class="text-md o-75" />
        </span>
        <span>{{text}}</span>
    </button>
</template>

<script>
    export default {
        props: {
            icon: { type: String },
            text: { type: String },
            hideIcon: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false },
        },
        methods: {
            click(e) {
                if (!this.disabled) {
                    this.$emit('click', e);
                }
            },
        },
    };
</script>

<style>
    .menu-item {
        display: block;
        width: 100%;
        border: none;
        background-color: white;
        color: #303133;
        text-align: left;
        padding: 10px 16px;
        cursor: pointer;
    }

    .menu-item:hover {
        background-color: #f2f6fc;
    }

    .menu-item:focus {
        background-color: #f2f6fc;
        outline: none;
    }

    .menu-item:active {
        background-color: #ebeef5;
        outline: none;
    }

    .menu-item.is-disabled {
        color: #b0b1b3 !important;
        cursor: not-allowed;
    }

    .menu-item.is-disabled:hover {
        background-color: transparent !important;
    }

    .menu-item.is-disabled:focus {
        background-color: transparent !important;
    }

    .menu-item.is-disabled:active {
        background-color: transparent !important;
    }
</style>