function getHoverElement(el, parentOffset) {
    if (typeof parentOffset === 'undefined') {
        while (!el.classList.contains('hover')) {
            el = el.parentNode;
            if (!el || !el.classList) {
                return null;
            }
        }
    }
    else if (typeof parentOffset === 'number') {
        if (parentOffset < 0) {
            return null;
        }

        for (let i = 0; i < parentOffset; i++) {
            el = el.parentNode;
            if (!el || !el.classList) {
                return null;
            }
        }
    }
    else {
        return null;
    }
    return el;
}

function showTargetElements(e) {
    for (const hover of e.target.targetElements) {
        hover.style.opacity = '1';
    }
}

function hideTargetElements(e) {
    for (const hover of e.target.targetElements) {
        hover.style.opacity = '0';
    }
}

function install(Vue) {
    Vue.directive('hover', {
        inserted(el, binding) {
            let hoverElement = getHoverElement(el, binding.value);
            if (hoverElement == null) {
                hoverElement = el;
            }

            if (!hoverElement.targetElements) {
                hoverElement.targetElements = [];
            }
            hoverElement.targetElements.push(el);

            hoverElement.removeEventListener('mouseenter', showTargetElements);
            hoverElement.removeEventListener('mouseleave', hideTargetElements);
            hoverElement.addEventListener('mouseenter', showTargetElements);
            hoverElement.addEventListener('mouseleave', hideTargetElements);

            el.style.opacity = '0';
        }
    });
}

export default { install };