<template>
    <el-input :value="value" :size="size" :placeholder="placeholder" @keyup.enter.native="search"
        @keyup.esc.native="clearSearch" @input="input">
        <i slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer;" @click="search"></i>
    </el-input>
</template>

<script>
    export default {
        props: {
            value: { type: String },
            size: { type: String },
            placeholder: { type: String, default: '搜索' },
        },
        methods: {
            search() {
                this.$emit('search');
            },
            clearSearch() {
                this.$emit('input', null);
                this.$emit('search');
            },
            input(value) {
                this.$emit('input', value);
            },
        },
    };
</script>