import ElementUI from 'element-ui';

function copy(text) {
    let input = document.createElement("input");
    input.setAttribute("value", text);
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    ElementUI.Message.success('复制成功。');
}

function install(Vue) {
    Vue.prototype.$copy = copy;
}

export default { install };