function install(Vue) {
    let requirePlugin = require.context('./', false, /\.js$/);
    requirePlugin.keys().forEach(fileName => {
        if (!fileName.endsWith('register.js')) {
            let pluginConfig = requirePlugin(fileName);
            Vue.use(pluginConfig.default || pluginConfig);
        }
    });
}

export default { install };