<template>
    <el-dialog title="选择知识点附件" :visible.sync="visible" :close-on-click-modal="false" append-to-body width="640px"
        @closed="$emit('input',false)">
        <div>
            <search-box v-model="key" @search="loadKnowledgeAttachments" />
        </div>
        <div class="narrow-scrollbar border rounded overflow-auto" style="height: 480px; margin-top: 5px;">
            <el-table :data="knowledgeAttachments.data" :show-header="false">
                <el-table-column prop="name" label="名称" sortable>
                    <template #default="scope">
                        <a :href="scope.row.url" download>{{scope.row.name}}</a>
                        <div class="text-info">
                            {{scope.row.creatorName}}
                            上传于
                            {{$moment.sd(scope.row.uploadDate)}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="80" align="right">
                    <template #default="scope">
                        <el-button type="primary" size="small" @click="selectKnowledgeAttachment(scope.row)">
                            选择
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="knowledgeAttachments.totalRecords"
                :current-page="knowledgeAttachmentsPagination.currentPage"
                :page-size="knowledgeAttachmentsPagination.pageSize" class="m-3"
                @current-change="knowledgeAttachmentsPaginationCurrentChange" />
        </div>
        <template #footer>
            <el-checkbox v-model="onlyShowMine" class="float-left" style="line-height: 40px;"
                @change="loadKnowledgeAttachments">仅显示我上传的附件</el-checkbox>
            <el-button @click="$emit('input',false)">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
            knowledgePointId: { type: String },
        },
        data() {
            return {
                visible: this.value,
                key: null,
                onlyShowMine: false,
                knowledgeAttachments: {},
                knowledgeAttachmentsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
            };
        },
        watch: {
            value(value) {
                this.visible = value;
                if (value) {
                    this.loadKnowledgeAttachments();
                }
            },
        },
        methods: {
            async loadKnowledgeAttachments() {
                let response = await this.$axios.get('/api/KnowledgeAttachment/GetKnowledgeAttachmentsFromKnowledgePoint', {
                    params: {
                        creatorId: this.onlyShowMine ? this.$store.state.user.id : '',
                        knowledgePointId: this.knowledgePointId,
                        key: this.key,
                        pageIndex: this.knowledgeAttachmentsPagination.currentPage - 1,
                        pageSize: this.knowledgeAttachmentsPagination.pageSize,
                    }
                });
                this.knowledgeAttachments = response.data;
            },
            knowledgeAttachmentsPaginationCurrentChange(page) {
                this.knowledgeAttachmentsPagination.currentPage = page;
                this.loadKnowledgeAttachments();
            },
            selectKnowledgeAttachment(knowledgeAttachment) {
                this.$emit('select', knowledgeAttachment);
                this.$emit('input', false);
            },
        },
    };
</script>