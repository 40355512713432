<template>
    <a href="javascript:void(0)" :style="activeTab==id?activeStyle:style" @click="onClick">
        <font-awesome-icon :icon="icon" style="font-size: 20px; margin-top: 8px;" />
        <div style="font-size: 12px; margin-top: 2px;">{{text}}</div>
    </a>
</template>

<script>
    export default {
        data() {
            return {
                style: { color: '#909399', width: '100%', height: '100%', textAlign: 'center', flexBasis: 0, flexGrow: 1, maxWidth: '100%' },
                activeStyle: { color: '#409eff', width: '100%', height: '100%', textAlign: 'center', flexBasis: 0, flexGrow: 1, maxWidth: '100%' },
            };
        },
        computed: {
            activeTab() {
                return this.$parent.value;
            },
        },
        props: {
            id: { type: String },
            icon: { type: String },
            text: { type: String },
        },
        methods: {
            onClick() {
                this.$parent.changeValue(this.id);
            },
        },
    };
</script>