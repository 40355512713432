<template>
    <el-dialog :visible.sync="visible" title="选择课程科目" :close-on-click-modal="false" append-to-body width="480px"
        @closed="$emit('input',false)">
        <div>
            <el-select v-model="courseSettingId" placeholder="课程设置">
                <el-option v-for="courseSettingNode in courseSettingNodes" :key="courseSettingNode.id"
                    :value="courseSettingNode.id" :label="courseSettingNode.name" />
            </el-select>
        </div>
        <div class="narrow-scrollbar border overflow-auto mt-2" style="height: 480px;">
            <el-menu v-if="visible" unique-opened class="border-0" @select="clickCourseSubject">
                <el-submenu v-for="gradeNode in gradeNodes" :key="gradeNode.grade"
                    :index="courseSettingId+gradeNode.grade.toString()" class="border-bottom border-light submenu">
                    <template #title>
                        {{gradeNode.gradeName}}
                    </template>
                    <div class="bg-light border-top border-light">
                        <el-menu-item v-for="courseSubject in gradeNode.courseSubjects" :key="courseSubject.id"
                            :index="courseSubject.id">
                            <font-awesome-icon icon="layer-group" fixed-width class="o-50" style="font-size: 16px;" />
                            <span class="ml-3">{{courseSubject.name}}</span>
                        </el-menu-item>
                    </div>
                </el-submenu>
            </el-menu>
        </div>
        <template #footer>
            <el-button type="primary" @click="selectCourseSubject">确定</el-button>
            <el-button @click="$emit('input',false)">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
        },
        data() {
            return {
                visible: this.value,
                courseSettingId: null,
                courseSettingNodes: [],
                courseSubjectId: null,
            };
        },
        computed: {
            gradeNodes() {
                if (!this.courseSettingId) {
                    return [];
                }

                let courseSettingNodes = this.courseSettingNodes.filter(p => p.id == this.courseSettingId);
                if (courseSettingNodes.length == 0) {
                    return [];
                }

                return courseSettingNodes[0].gradeNodes;
            },
        },
        watch: {
            value(value) {
                this.visible = value;
                if (this.visible) {
                    this.courseSubjectId = null;
                    this.loadCourseSubjectsTree();
                }
            },
        },
        methods: {
            async loadCourseSubjectsTree() {
                let response = await this.$axios.get('/api/CourseSubject/GetCourseSubjectsTree', { params: { campusId: this.$store.state.campusId } });
                this.courseSettingNodes = response.data;

                if (this.courseSettingNodes.length != 0) {
                    this.courseSettingId = this.courseSettingNodes[0].id;
                }
            },
            clickCourseSubject(courseSubjectId) {
                this.courseSubjectId = courseSubjectId;
            },
            selectCourseSubject() {
                if (this.courseSubjectId == null) {
                    return;
                }

                this.$emit('select', this.courseSubjectId);
                this.visible = false;
            },
        },
    };
</script>