<template>
    <div>
        <div v-for="(file,index) in files" :key="file.url" :class="{'mt-1':index!=0}"
            class="file-list-item rounded row no-gutters p-3">
            <div class="col">
                <i class="el-icon-tickets text-md text-info"></i>
                <a :href="file.url" target="_blank" class="ml-2">{{file.name}}</a>
            </div>
            <div class="col-auto">
                <a :href="file.url" download title="下载" class="ml-3">
                    <i class="el-icon-download"></i>
                </a>
                <a v-if="!readonly" href="javascript:void(0)" title="删除" class="ml-3" style="color: red;"
                    @click="deleteFile(file)">
                    <i class="el-icon-close"></i>
                </a>
            </div>
        </div>
        <div v-if="!readonly&&files.length<limit" :class="{'mt-1':files.length!=0}">
            <file-uploader :accept="accept" multiple class="d-inline-block" @before-upload="beforeUpload"
                @upload-success="uploadSuccess" @upload-error="uploadError">
                <template #default>
                    <el-button v-if="!readonly" :loading="uploading" plain>
                        上传文件
                    </el-button>
                </template>
            </file-uploader>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: { type: Array, default: () => [] },
            readonly: { type: Boolean, default: false },
            limit: { type: Number, default: 64 },
            accept: { type: String },
        },
        data() {
            return {
                files: this.value,
                uploading: false,
            };
        },
        watch: {
            value(value) {
                this.files = value;
            },
        },
        methods: {
            beforeUpload() {
                this.uploading = true;
            },
            uploadSuccess(file) {
                this.uploading = false;
                this.files.push({
                    name: file.originalFileName,
                    url: file.fileUrl,
                    extension: file.fileExtension,
                    size: file.fileSize,
                });
                this.$emit('input', this.files);
            },
            uploadError() {
                this.uploading = false;
                this.$message.error('上传失败！');
            },
            deleteFile(file) {
                let index = this.files.indexOf(file);
                this.files.splice(index, 1);
                this.$emit('input', this.files);
            },
        },
    };
</script>

<style>
    .file-list-item {
        background-color: #f9f9f9;
    }

    .file-list-item:hover {
        background-color: #f3f3f3;
    }
</style>