<template>
    <el-dialog :visible.sync="visible" title="选择学生" :close-on-click-modal="false" append-to-body width="640px"
        @closed="$emit('input',false)">
        <div class="mt-2">
            <search-box v-model="key" @search="loadStudents" />
        </div>
        <div class="narrow-scrollbar border overflow-auto mt-2" style="height: 480px;">
            <el-table :data="students">
                <el-table-column prop="name" label="姓名" sortable />
                <el-table-column prop="phone" label="电话" sortable />
                <el-table-column prop="gradeName" label="年级" sortable />
                <el-table-column label="操作" fixed="right" width="80">
                    <template #default="scope">
                        <el-button type="primary" size="small" @click="selectStudent(scope.row)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <template #footer>
            <el-button @click="$emit('input',false)">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
        },
        data() {
            return {
                visible: this.value,
                key: null,
                students: [],
            };
        },
        watch: {
            value(value) {
                this.visible = value;
                if (this.visible) {
                    this.key = null;
                    this.students = [];
                }
            },
        },
        methods: {
            async loadStudents() {
                let response = await this.$axios.get('/api/Student/GetStudents', {
                    params: {
                        campusId: this.$store.state.campusId,
                        key: this.key,
                        pageSize: 100,
                    }
                });
                this.students = response.data.data;
            },
            selectStudent(courseClass) {
                this.$emit('select', courseClass);
                this.visible = false;
            },
        },
    };
</script>