import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

function install(Vue) {
    let requireComponent = require.context('./', true, /\.vue$/);
    requireComponent.keys().forEach(fileName => {
        let componentConfig = requireComponent(fileName);
        let componentName = upperFirst(camelCase(fileName.split('/').pop().replace(/\.\w+$/, '')));
        Vue.component(componentName, componentConfig.default || componentConfig);
    });
}

export default { install };