import menus from './menus';

function getRoutes() {
    let result = [];
    for (const menuGroup of menus) {
        for (const menuItem of menuGroup.menuItems) {
            result.push({
                path: menuItem.path,
                meta: { requiresAuth: true },
                component: menuItem.component,
            });
        }
    }
    return result;
}

function getMenus(user) {
    if (!user) {
        return [];
    }

    let result = [];
    for (const menuGroup of menus) {
        let menuGroup2 = { name: menuGroup.name, expanded: menuGroup.expanded, menuItems: [] };
        for (const menuItem of menuGroup.menuItems) {
            if (!menuItem.permission || user.permissions.indexOf(menuItem.permission) >= 0) {
                menuGroup2.menuItems.push(menuItem);
            }
        }
        if (menuGroup2.menuItems.length != 0) {
            result.push(menuGroup2);
        }
    }
    return result;
}

export default { getRoutes, getMenus };