<template>
    <el-dialog :visible.sync="visible" title="选择试题" :close-on-click-modal="false" append-to-body width="960px"
        top="48px" @closed="$emit('input',false)">
        <div class="border" style="height: 560px;">
            <el-container class="h-100">
                <el-aside width="320px" class="bg-white border-right">
                    <el-container class="h-100">
                        <el-header height="auto" class="p-2 border-bottom border-light">
                            <el-row :gutter="8">
                                <el-col :span="12">
                                    <el-select v-model="grade" placeholder="年级" @change="loadKnowledgePointTreeNodes">
                                        <el-option v-for="grade in $store.state.dataDefinitions.grades" :key="grade.key"
                                            :value="grade.key" :label="grade.value" />
                                    </el-select>
                                </el-col>
                                <el-col :span="12">
                                    <el-select v-model="subject" placeholder="科目" @change="loadKnowledgePointTreeNodes">
                                        <el-option v-for="subject in $store.state.dataDefinitions.subjects"
                                            :key="subject.key" :value="subject.key" :label="subject.value" />
                                    </el-select>
                                </el-col>
                            </el-row>
                        </el-header>
                        <el-main class="p-0 narrow-scrollbar">
                            <el-tree ref="knowledgePointTree" :data="knowledgePointTreeNodes" node-key="id"
                                highlight-current default-expand-all :expand-on-click-node="false"
                                @current-change="selectKnowledgePointTreeNode" />
                        </el-main>
                    </el-container>
                </el-aside>
                <el-main class="p-0 narrow-scrollbar">
                    <el-table :data="questions.data">
                        <el-table-column prop="title" label="题目" sortable />
                        <el-table-column width="80" align="right">
                            <template #default="scope">
                                <el-button type="primary" size="small" @click="selectQuestion(scope.row)">
                                    选择
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination layout="total,->,prev,pager,next" :total="questions.totalRecords"
                        :current-page="questionsPagination.currentPage" :page-size="questionsPagination.pageSize"
                        class="m-3" @current-change="questionsPaginationCurrentChange" />
                </el-main>
            </el-container>
        </div>
        <template #footer>
            <el-button @click="$emit('input',false)">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
        },
        data() {
            return {
                visible: this.value,
                grade: 1,
                subject: this.$store.state.dataDefinitions.subjects[0].key,
                knowledgePointTreeNodes: [],
                knowledgePointTreeNode: null,
                questions: {},
                questionsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
            };
        },
        watch: {
            value(value) {
                this.visible = value;
                if (this.visible) {
                    this.questions = [];
                    this.questionsPagination.currentPage = 1;
                    this.loadKnowledgePointTreeNodes();
                }
            },
        },
        methods: {
            async loadKnowledgePointTreeNodes() {
                let response = await this.$axios.get('/api/KnowledgePoint/GetKnowledgePointTreeNodes', { params: { grade: this.grade, subject: this.subject } });
                this.knowledgePointTreeNodes = response.data;
            },
            selectKnowledgePointTreeNode(data) {
                this.knowledgePointTreeNode = data;
                this.loadQuestions();
            },
            async loadQuestions() {
                let response = await this.$axios.get('/api/Question/GetQuestions', {
                    params: {
                        knowledgePointId: this.knowledgePointTreeNode.id,
                        pageIndex: this.questionsPagination.currentPage - 1,
                        pageSize: this.questionsPagination.pageSize,
                    }
                });
                this.questions = response.data;
            },
            questionsPaginationCurrentChange(page) {
                this.questionsPagination.currentPage = page;
                this.loadQuestions();
            },
            selectQuestion(question) {
                this.$emit('select', question);
                this.visible = false;
            },
        },
    };
</script>