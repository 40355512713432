<template>
    <el-dialog :visible.sync="visible" title="选择教室" :close-on-click-modal="false" append-to-body width="480px"
        @closed="$emit('input',false)">
        <div class="narrow-scrollbar border overflow-auto mt-2" style="height: 480px;">
            <el-table :data="classrooms" :show-header="false">
                <el-table-column prop="name" label="名称" sortable />
                <el-table-column width="80" align="right">
                    <template #default="scope">
                        <el-button type="primary" size="small" @click="selectClassroom(scope.row)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <template #footer>
            <el-button @click="$emit('input',false)">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
        },
        data() {
            return {
                visible: this.value,
                classrooms: [],
            };
        },
        watch: {
            value(value) {
                this.visible = value;
                if (this.visible) {
                    this.loadClassrooms();
                }
            },
        },
        methods: {
            async loadClassrooms() {
                let response = await this.$axios.get('/api/Classroom/GetClassrooms', { params: { campusId: this.$store.state.campusId } });
                this.classrooms = response.data;
            },
            selectClassroom(classroom) {
                this.$emit('select', classroom);
                this.visible = false;
            },
        },
    };
</script>