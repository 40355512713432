<template>
    <div>
        <el-image v-if="type=='image'" :src="src" class="w-100 h-100" style="object-fit: cover;" />
        <div v-else-if="type=='video'" class="w-100 h-100 position-relative">
            <video ref="video" :src="src" class="w-100 h-100"></video>
            <div class="position-absolute d-flex justify-content-center align-items-center video-controller"
                style="top: 0; bottom: 0; left: 0; right: 0; z-index: 10; cursor: pointer;" @click="playAndpause">
                <div class="px-3 py-2 rounded-pill" style="background-color: rgba(0, 0, 0, 0.0.25);">
                    <font-awesome-icon icon="play" class="text-white" style="font-size: 24px;" />
                    <font-awesome-icon icon="pause" class="text-white ml-3" style="font-size: 24px;" />
                </div>
            </div>
        </div>
        <div v-else class="w-100 h-100 bg-light d-flex justify-content-center align-items-center">
            <font-awesome-icon icon="file" style="color: #dddddd; font-size: 32px;" />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            src: { type: String },
        },
        computed: {
            type() {
                if (!this.src) {
                    return '';
                }

                let src = this.src.toLowerCase();
                if (src.endsWith('.png') || src.endsWith('.jpg') || src.endsWith('.gif')) {
                    return 'image';
                }
                else if (src.endsWith('.mp4')) {
                    return 'video';
                }
                else {
                    return '';
                }
            },
        },
        methods: {
            playAndpause() {
                if (this.$refs.video.paused) {
                    this.$refs.video.play();
                }
                else {
                    this.$refs.video.pause();
                }
            },
        },
    };
</script>

<style>
    .video-controller {
        opacity: 0;
    }

    .video-controller:hover {
        opacity: 1;
    }
</style>