import ElementUI from 'element-ui';
import axios from 'axios';
import qs from 'qs';

let loading;
let tasks = 0;

// x-www-form-urlencoded
axios.interceptors.request.use(config => {
    if (config.method == 'post') {
        config.data = qs.stringify(config.data);
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// authorization
axios.interceptors.request.use(config => {
    if (localStorage.token) {
        config.headers.Authorization = 'Bearer ' + localStorage.token;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// handle error
axios.interceptors.request.use(config => {
    return config;
}, error => {
    handleError(error);
    return Promise.reject(error);
});
axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status == 401) {
        localStorage.removeItem('token');
        location.reload();
    }
    else {
        handleError(error);
    }
    return Promise.reject(error);
});
function handleError(error) {
    if (error.response) {
        if (typeof (error.response.data) == 'object') {
            window.console.error('[Server error]: ' + error.response.data.message + '\n' + error.response.data.detail);
            ElementUI.MessageBox.alert(error.response.data.message, '错误', { type: 'error' });
        }
        else {
            if (error.response.data) {
                ElementUI.MessageBox.alert(error.response.data, '错误', { type: 'error' });
            }
            else {
                ElementUI.MessageBox.alert(error.message, '错误', { type: 'error' });
            }
        }
    }
    else {
        ElementUI.MessageBox.alert(error.message, '错误', { type: 'error' });
    }
}

// loading
axios.interceptors.request.use(config => {
    beginLoading();
    return config;
}, error => {
    endLoading();
    return Promise.reject(error);
});
axios.interceptors.response.use(response => {
    endLoading();
    return response;
}, error => {
    endLoading();
    return Promise.reject(error);
});
function beginLoading() {
    loading = ElementUI.Loading.service({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'transparent',
        customClass: 'loading',
    });
    tasks++;
}
function endLoading() {
    tasks--;
    if (tasks <= 0) {
        tasks = 0;
        loading.close();
    }
}

function install(Vue) {
    Vue.prototype.$axios = axios;
}

export default { install };