<template>
    <div :style="style">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                style: { backgroundColor: 'white', display: 'flex', height: '56px', borderTop: '1px solid #ebeef5' },
            };
        },
        props: {
            value: { type: String },
        },
        methods: {
            changeValue(value) {
                this.$emit('input', value);
            },
        },
    };
</script>