import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import menu from '../menu';
import Main from '../views/Admin/Main.vue';
import Login from '../views/Admin/Login.vue';
import Initialize from '../views/Admin/Initialize.vue';
import PageNotFound from '../views/Shared/PageNotFound.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        meta: { requiresAuth: true },
        component: Main,
        children: [
            {
                path: '',
                meta: { requiresAuth: true },
                component: () => import('../views/Admin/Home.vue')
            },
            ...menu.getRoutes()
        ]
    },
    {
        path: '/bind',
        component: () => import('../views/Admin/Bind.vue')
    },
    {
        path: '/m',
        meta: { requiresAuth: true },
        component: () => import('../views/Mobile/Index.vue')
    },
    {
        path: '/student/detail',
        meta: { requiresAuth: true },
        component: () => import('../views/Student/Detail.vue')
    },
    {
        path: '/lesson/detail',
        meta: { requiresAuth: true },
        component: () => import('../views/Lesson/Detail.vue')
    },
    {
        path: '/course-class/detail',
        meta: { requiresAuth: true },
        component: () => import('../views/CourseClass/Detail.vue')
    },
    {
        path: '/order/detail',
        meta: { requiresAuth: true },
        component: () => import('../views/Order/Detail.vue')
    },
    {
        path: '/order/print',
        meta: { requiresAuth: true },
        component: () => import('../views/Order/Print.vue')
    },
    {
        path: '/student/nuclein-report-print',
        meta: { requiresAuth: true },
        component: () => import('../views/Student/NucleinReportPrint.vue')
    },
    {
        path: '/application-form/audit-flow',
        meta: { requiresAuth: true },
        component: () => import('../views/ApplicationForm/AuditFlow.vue')
    },
    {
        path: '/screen/campus',
        meta: { requiresAuth: true },
        component: () => import('../views/Screen/Campus.vue')
    },
    {
        path: '/screen/course-setting',
        meta: { requiresAuth: true },
        component: () => import('../views/Screen/CourseSetting.vue')
    },
    {
        path: '/login-wechatoa',
        component: () => import('../views/Admin/LoginWeChatOA.vue')
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/initialize',
        component: Initialize
    },
    {
        path: '*',
        component: PageNotFound
    },
];

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        if (localStorage.token) {
            if (store.state.user) {
                next();
            }
            else {
                next({ path: '/initialize', query: { redirect: to.fullPath } });
            }
        }
        else {
            next({ path: '/login', query: { redirect: to.fullPath } });
        }
    }
    else {
        next();
    }
});

export default router;