<template>
    <el-amap :center="center2" :zoom="zoom" :events="events">
        <el-amap-marker v-if="value" :position="value" />
    </el-amap>
</template>

<script>
    let _this = null;

    export default {
        data() {
            return {
                center2: null,
                events: {
                    click(e) {
                        _this.$emit('input', [e.lnglat.lng, e.lnglat.lat]);
                        _this.$emit('change', [e.lnglat.lng, e.lnglat.lat]);
                    },
                },
            };
        },
        props: {
            value: { type: Array },
            center: { type: Array, default: () => [116.397455, 39.908741] },
            zoom: { type: Number, default: 15 },
        },
        watch: {
            value(val) {
                if (val) {
                    this.center2 = val;
                }
            },
        },
        created() {
            this.center2 = this.value || this.center;

            _this = this;
        },
    };
</script>

<style>
    .amap-logo,
    .amap-copyright {
        opacity: 0;
    }
</style>