<template>
    <el-dialog title="选择组织机构" :visible.sync="visible" :close-on-click-modal="false" append-to-body width="400px"
        @closed="$emit('input',false)">
        <div class="narrow-scrollbar border rounded overflow-auto" style="height: 400px;">
            <el-tree ref="organizationTree" :data="organizationTreeNodes" show-checkbox :check-strictly="!cascade"
                node-key="id" highlight-current default-expand-all :expand-on-click-node="false" />
        </div>
        <template #footer>
            <el-checkbox v-model="cascade" class="float-left" style="line-height: 40px;">级联</el-checkbox>
            <el-button type="primary" @click="selectOrganizations">确定</el-button>
            <el-button @click="$emit('input',false)">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
            initSelectedOrganizations: { type: Array, default() { return []; } },
            defaultCascade: { type: Boolean, default: false },
        },
        data() {
            return {
                visible: this.value,
                cascade: this.defaultCascade,
                organizationTreeNodes: [],
                organizationId: null,
                organizationName: null,
            };
        },
        watch: {
            value(value) {
                this.visible = value;
                if (value) {
                    this.loadOrganizationTreeNodes();
                }
            },
        },
        methods: {
            async loadOrganizationTreeNodes() {
                let response = await this.$axios.get('/api/Organization/GetOrganizationTreeNodes');
                this.organizationTreeNodes = response.data;
                this.$refs.organizationTree.setCheckedKeys(this.initSelectedOrganizations.map(p => p.id));
            },
            selectOrganizations() {
                var checkedNodes = this.$refs.organizationTree.getCheckedNodes();
                this.$emit('select', checkedNodes.map(p => { return { id: p.id, name: p.label }; }));
                this.$emit('input', false);
            },
        },
    };
</script>