<template>
    <el-dialog :visible.sync="visible" :width="width" :top="top" append-to-body custom-class="action-sheet"
        class="hide-dialog-header" @closed="$emit('input',false)">
        <div v-if="title" class="text-center font-weight-bold p-2 border-bottom">{{title}}</div>
        <slot></slot>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: this.value,
            };
        },
        props: {
            value: { type: Boolean, default: false },
            title: { type: String },
            width: { type: String, default: '75%' },
            top: { type: String, default: '25vh' },
        },
        watch: {
            value(value) {
                this.visible = value;
            },
        },
    };
</script>

<style>
    .action-sheet .el-dialog__body {
        padding: 0 !important;
    }
</style>