<template>
    <div class="d-inline-block" style="width: 100%;">
        <el-input :value="value.name" readonly :placeholder="placeholder">
            <template #append>
                <el-button v-if="!readonly" @click="visible=true">选择</el-button>
            </template>
        </el-input>
        <select-user-dialog v-model="visible" :default-cascade="defaultCascade" @select="selectUser" />
    </div>
</template>

<script>
    export default {
        props: {
            value: { type: Object },
            readonly: { type: Boolean },
            placeholder: { type: String },
            defaultCascade: { type: Boolean, default: false },
        },
        data() {
            return {
                visible: false,
            };
        },
        methods: {
            selectUser(user) {
                this.$emit('input', user);
                this.$emit('select', user);
            },
        },
    };
</script>