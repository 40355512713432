<template>
    <el-dialog :visible.sync="visible" title="选择班级" :close-on-click-modal="false" append-to-body width="720px"
        @closed="$emit('input',false)">
        <el-row :gutter="8">
            <el-col :span="8">
                <el-select :disabled="this.$store.state.user.organizationId.length>=8" v-model="campusId"
                    placeholder="校区" @change="loadCourseClasses">
                    <el-option v-for="campus in $store.state.campuses" :key="campus.id" :value="campus.id"
                        :label="campus.name">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="8">
                <el-select v-model="courseSettingId" placeholder="课程设置">
                    <el-option :value="null" label="全部课程设置" />
                    <el-option v-for="courseSetting in courseSettings" :key="courseSetting.id" :value="courseSetting.id"
                        :label="courseSetting.name" />
                </el-select>
            </el-col>
            <el-col :span="8">
                <el-select v-model="grade" placeholder="年级">
                    <el-option :value="null" label="全部年级" />
                    <el-option v-for="grade in $store.state.dataDefinitions.grades" :key="grade.key" :value="grade.key"
                        :label="grade.value" />
                </el-select>
            </el-col>
        </el-row>
        <el-row :gutter="8" class="mt-2">
            <el-col :span="8">
                <el-select v-model="subject" placeholder="科目">
                    <el-option :value="null" label="全部科目" />
                    <el-option v-for="subject in $store.state.dataDefinitions.subjects" :key="subject.key"
                        :value="subject.key" :label="subject.value" />
                </el-select>
            </el-col>
            <el-col :span="8">
                <el-select v-model="classTime" placeholder="时间段">
                    <el-option :value="null" label="全部时间段" />
                    <el-option v-for="classTime in classTimes" :key="classTime.key" :value="classTime.key"
                        :label="classTime.value" />
                </el-select>
            </el-col>
            <el-col :span="8">
                <search-box v-model="key" />
            </el-col>
        </el-row>
        <div class="narrow-scrollbar border overflow-auto mt-2" style="height: 480px;">
            <el-table :data="filteredCourseClasses" :show-header="false">
                <el-table-column>
                    <template #default="scope">
                        <div class="font-weight-bold">
                            {{scope.row.name}}
                        </div>
                        <div class="mt-1">
                            <span class="bg-warning text-white rounded px-2">{{scope.row.subjectPrice}} 元</span>
                            <span class="border border-primary rounded px-2 ml-1">{{scope.row.classroomName}}</span>
                            <span class="border border-secondary rounded px-2 ml-1">{{scope.row.classTimeName}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="80" align="right">
                    <template #default="scope">
                        <el-button type="primary" size="small" @click="selectCourseClass(scope.row)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <template #footer>
            <el-button @click="$emit('input',false)">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
            initGrade: { type: Number, default: null },
            initSubject: { type: Number, default: null },
            initClassTime: { type: Number, default: null },
        },
        data() {
            return {
                visible: this.value,
                courseSettings: [],
                classTimes: [],
                campusId: this.$store.state.campusId,
                courseSettingId: null,
                grade: null,
                subject: null,
                classTime: null,
                key: null,
                courseClasses: [],
            };
        },
        computed: {
            filteredCourseClasses() {
                let result = this.courseClasses;
                if (this.courseSettingId != null) {
                    result = result.filter(p => p.courseSettingId == this.courseSettingId);
                }
                if (this.grade != null) {
                    result = result.filter(p => p.grade == this.grade);
                }
                if (this.subject != null) {
                    result = result.filter(p => p.subject == this.subject);
                }
                if (this.classTime != null) {
                    result = result.filter(p => p.classTime == this.classTime);
                }
                if (this.key) {
                    result = result.filter(p => p.name.indexOf(this.key) >= 0 || p.displayName.indexOf(this.key) >= 0);
                }
                return result;
            },
        },
        watch: {
            value(value) {
                this.visible = value;
                if (this.visible) {
                    this.grade = this.initGrade;
                    this.subject = this.initSubject;
                    this.classTime = this.initClassTime;
                    this.loadCourseSettings();
                    this.loadClassTimes();
                    this.loadCourseClasses();
                }
            },
        },
        methods: {
            async loadCourseSettings() {
                let response = await this.$axios.get('/api/CourseSetting/GetCourseSettings', {
                    params: {
                        campusId: this.$store.state.campusId,
                        isOpened: true,
                    }
                });
                this.courseSettings = response.data;
            },
            async loadClassTimes() {
                let response = await this.$axios.get('/api/ClassTime/GetClassTimes', { params: { campusId: this.$store.state.campusId } });
                this.classTimes = response.data;
            },
            async loadCourseClasses() {
                let response = await this.$axios.get('/api/CourseClass/GetOpenedCourseClasses', {
                    params: {
                        campusId: this.campusId,
                        grade: null,
                        subject: null,
                    }
                });
                this.courseClasses = response.data;
            },
            selectCourseClass(courseClass) {
                this.$emit('select', courseClass);
                this.visible = false;
            },
        },
    };
</script>