<template>
    <el-dialog title="选择用户" :visible.sync="visible" :close-on-click-modal="false" append-to-body width="880px"
        top="48px" @closed="$emit('input',false)">
        <el-row :gutter="8">
            <el-col :span="8" style="line-height: 28px;">组织机构</el-col>
            <el-col :span="8" style="line-height: 28px;">
                用户
                ({{filteredUsers.length}})
                <div class="float-right">
                    <el-button type="text" size="small" @click="selectAll">全选</el-button>
                    <el-button type="text" size="small" @click="clearSelect">全否</el-button>
                </div>
                <div class="clearfix"></div>
            </el-col>
            <el-col :span="8" style="line-height: 28px;">
                已选用户
                ({{selectedUsers.length}})
            </el-col>
        </el-row>
        <el-row :gutter="8" style="margin-top: 5px;">
            <el-col :span="8">
                <div class="narrow-scrollbar border rounded overflow-auto" style="height: 480px;">
                    <el-tree ref="organizationTree" :data="organizationTreeNodes" node-key="id" highlight-current
                        default-expand-all :expand-on-click-node="false" @current-change="selectOrganizationTreeNode" />
                </div>
            </el-col>
            <el-col :span="8">
                <el-input v-model="key" clearable size="medium" placeholder="搜索" />
                <div class="narrow-scrollbar border rounded overflow-auto" style="height: 439px; margin-top: 5px;">
                    <el-table :data="filteredUsers" :show-header="false">
                        <el-table-column width="40" align="center">
                            <template #default="scope">
                                <el-checkbox v-model="scope.row.isSelected"></el-checkbox>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template #default="scope">
                                <div :title="scope.row.organizationName">
                                    {{scope.row.name}}
                                    <span class="text-info">{{scope.row.userName}}</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="narrow-scrollbar border rounded overflow-auto" style="height: 480px;">
                    <el-table :data="selectedUsers" :show-header="false">
                        <el-table-column>
                            <template #default="scope">
                                <div :title="scope.row.organizationName">
                                    {{scope.row.name}}
                                    <span class="text-info">{{scope.row.userName}}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="40" align="right">
                            <template #default="scope">
                                <el-button type="danger" size="small" circle icon="el-icon-close" style="padding: 3px;"
                                    @click="scope.row.isSelected=false"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
        <template #footer>
            <el-checkbox v-model="cascade" class="float-left" style="line-height: 40px;">级联</el-checkbox>
            <el-button type="primary" @click="selectUsers">确定</el-button>
            <el-button @click="$emit('input',false)">取消</el-button>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            value: { type: Boolean, default: false },
            initSelectedUsers: { type: Array, default() { return []; } },
            defaultCascade: { type: Boolean, default: false },
        },
        data() {
            return {
                visible: this.value,
                cascade: this.defaultCascade,
                organizationTreeNodes: [],
                organizationId: '',
                organizationName: '',
                key: null,
                users: [],
            };
        },
        computed: {
            filteredUsers() {
                if (this.key) {
                    return this.users.filter(p => p.userName.indexOf(this.key) != -1 || p.name.indexOf(this.key) != -1);
                }
                else {
                    if (this.cascade) {
                        return this.users.filter(p => p.organizationId.substr(0, this.organizationId.length) == this.organizationId);
                    }
                    else {
                        return this.users.filter(p => p.organizationId == this.organizationId);
                    }
                }
            },
            selectedUsers() {
                return this.users.filter(p => p.isSelected);
            },
        },
        watch: {
            value(value) {
                this.visible = value;
                if (value) {
                    this.loadOrganizationTreeNodes();
                    this.loadUsers();
                }
            },
        },
        methods: {
            async loadOrganizationTreeNodes() {
                let response = await this.$axios.get('/api/Organization/GetOrganizationTreeNodes');
                this.organizationTreeNodes = response.data;
                if (response.data.length > 0) {
                    this.$nextTick(() => {
                        this.$refs.organizationTree.setCurrentKey(response.data[0].id);
                        this.selectOrganizationTreeNode(response.data[0]);
                    });
                }
            },
            selectOrganizationTreeNode(data) {
                this.key = null;
                this.organizationId = data.id;
                this.organizationName = data.label;
            },
            async loadUsers() {
                this.users = [];
                let response = await this.$axios.get('/api/User/GetUsers');
                for (let i = 0; i < response.data.length; i++) {
                    let user = response.data[i];
                    user.isSelected = this.initSelectedUsers.filter(p => p.id == user.id).length != 0;
                }
                this.users = response.data;
            },
            selectUsers() {
                this.$emit('select', this.selectedUsers);
                this.$emit('input', false);
            },
            selectAll() {
                this.filteredUsers.forEach(p => (p.isSelected = true));
            },
            clearSelect() {
                this.filteredUsers.forEach(p => (p.isSelected = false));
            },
        },
    };
</script>