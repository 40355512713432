import VueAMap from 'vue-amap';

function install(Vue) {
    Vue.use(VueAMap);
    VueAMap.initAMapApiLoader({
        key: '0fbcd41030b5f4403c14c79cbecb6ce6',
        plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
        v: '1.4.4'
    });
}

export default { install };