<template>
    <div class="d-inline-block" style="width: 100%;">
        <el-input :value="value.name" readonly :placeholder="placeholder">
            <template #append>
                <el-button v-if="!readonly" @click="visible=true">选择</el-button>
            </template>
        </el-input>
        <select-organization-dialog v-model="visible" @select="selectOrganization" />
    </div>
</template>

<script>
    export default {
        props: {
            value: { type: Object },
            readonly: { type: Boolean },
            placeholder: { type: String },
        },
        data() {
            return {
                visible: false,
            };
        },
        methods: {
            selectOrganization(organization) {
                this.$emit('input', organization);
                this.$emit('select', organization);
            },
        },
    };
</script>