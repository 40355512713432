export default [
    {
        name: '学生',
        expanded: false,
        menuItems: [
            { permission: 'Student', name: '注册学生管理', icon: 'user-graduate', path: '/student', component: () => import('../views/Student/Index.vue') },
            { permission: 'Student_Special', name: '特殊学生', icon: 'user-graduate', path: '/student/special', component: () => import('../views/Student/Special.vue') },
            { permission: 'Student_Reading', name: '在读学生', icon: 'user-graduate', path: '/student/reading', component: () => import('../views/Student/Reading.vue') },
            { permission: 'Student_NotReading', name: '非在读学生', icon: 'user-graduate', path: '/student/not-reading', component: () => import('../views/Student/NotReading.vue') },
            { permission: 'VisitRecord', name: '前台接待', icon: 'street-view', path: '/visit-record', component: () => import('../views/VisitRecord/Index.vue') },
            { permission: 'Student_RevisitStudent', name: '到期待回访', icon: 'history', path: '/student/revisit-student', component: () => import('../views/Student/RevisitStudent.vue') },
            { permission: 'Student_VisitTrigger', name: '触发待回访', icon: 'history', path: '/student/visit-trigger', component: () => import('../views/Student/VisitTrigger.vue') },
            { permission: 'Student_NucleinReport', name: '核酸报告', icon: 'flask', path: '/student/nuclein-report', component: () => import('../views/Student/NucleinReport.vue') },
        ]
    },
    {
        name: '教师',
        expanded: false,
        menuItems: [
            { permission: 'Teacher_CourseClass', name: '我的班级', icon: 'cubes', path: '/teacher/course-class', component: () => import('../views/Teacher/CourseClass.vue') },
            { permission: 'Teacher_CourseSupervisionAssistant', name: '巡课监管', icon: 'desktop', path: '/teacher/course-supervision-assistant', component: () => import('../views/Teacher/CourseSupervisionAssistant.vue') },
            { permission: 'Teacher_CourseSupervision', name: '课程监管', icon: 'desktop', path: '/teacher/course-supervision', component: () => import('../views/Teacher/CourseSupervision.vue') },
        ]
    },
    {
        name: '申请单',
        expanded: false,
        menuItems: [
            { permission: 'ApplicationFormType', name: '申请单类型', icon: 'clone', path: '/application-form-type', component: () => import('../views/ApplicationFormType/Index.vue') },
            { permission: 'ApplicationForm', name: '我的申请', icon: 'sticky-note', path: '/application-form', component: () => import('../views/ApplicationForm/Index.vue') },
            { permission: 'ApplicationForm_Audit', name: '审核', icon: 'user-shield', path: '/application-form/audit', component: () => import('../views/ApplicationForm/Audit.vue') },
            { permission: 'ApplicationForm_RefundOrderAudit', name: '学生退款审核', icon: 'user-shield', path: '/application-form/refund-order-audit', component: () => import('../views/ApplicationForm/RefundOrderAudit.vue') },
            { permission: 'ApplicationForm_Collection', name: '申请汇总', icon: 'sticky-note', path: '/application-form/collection', component: () => import('../views/ApplicationForm/Collection.vue') },
        ]
    },
    {
        name: '财务',
        expanded: false,
        menuItems: [
            { permission: 'Order', name: '学生报名交费', icon: 'clipboard-check', path: '/order', component: () => import('../views/Order/Index.vue') },
            { permission: 'OtherFee', name: '其他收入', icon: 'clipboard-list', path: '/other-fee', component: () => import('../views/OtherFee/Index.vue') },
            { permission: 'ApplicationForm_Expense', name: '报销支出', icon: 'yen-sign', path: '/application-form/expense', component: () => import('../views/ApplicationForm/Expense.vue') },
            { permission: 'Stats_FundFlow', name: '日流水', icon: 'donate', path: '/stats/fund-flow', component: () => import('../views/Stats/FundFlow.vue') },
            { permission: 'Stats_IncomeAndExpense', name: '收支汇总报表', icon: 'chart-area', path: '/stats/income-and-expense', component: () => import('../views/Stats/IncomeAndExpense.vue') },
            { permission: 'Stats_ClassStudent', name: '班级学生管理', icon: 'users-cog', path: '/stats/class-student', component: () => import('../views/Stats/ClassStudent.vue') },
            { permission: 'FeeType', name: '费用类型', icon: 'coins', path: '/fee-type', component: () => import('../views/FeeType/Index.vue') },
            { permission: 'FreezedLessonStudent', name: '冻结的课程', icon: 'asterisk', path: '/freezed-lesson-student', component: () => import('../views/FreezedLessonStudent/Index.vue') },

        ]
    },
    {
        name: '课程',
        expanded: false,
        menuItems: [
            { permission: 'CourseSetting', name: '课程设置', icon: 'boxes', path: '/course-setting', component: () => import('../views/CourseSetting/Index.vue') },
            { permission: 'CourseSubject', name: '课程科目', icon: 'layer-group', path: '/course-subject', component: () => import('../views/CourseSubject/Index.vue') },
            { permission: 'CourseClass', name: '班级设置', icon: 'cubes', path: '/course-class', component: () => import('../views/CourseClass/Index.vue') },
            { permission: 'Classroom', name: '教室设置', icon: 'door-closed', path: '/classroom', component: () => import('../views/Classroom/Index.vue') },
            { permission: 'ClassTime', name: '时间段设置', icon: 'clock', path: '/class-time', component: () => import('../views/ClassTime/Index.vue') },
            { permission: 'Lesson_UpdateLessonsDate', name: '按课调课', icon: 'retweet', path: '/lesson/update-lessons-date', component: () => import('../views/Lesson/UpdateLessonsDate.vue') },
            { permission: 'Lesson_UpdateCourseClassDate', name: '按班调课', icon: 'retweet', path: '/lesson/update-course-class-date', component: () => import('../views/Lesson/UpdateCourseClassDate.vue') },
        ]
    },
    {
        name: '知识点',
        expanded: false,
        menuItems: [
            { permission: 'KnowledgePoint', name: '知识点', icon: 'dot-circle', path: '/knowledge-point', component: () => import('../views/KnowledgePoint/Index.vue') },
            { permission: 'Question', name: '题库', icon: 'scroll', path: '/question', component: () => import('../views/Question/Index.vue') },
            { permission: 'KnowledgeAttachment', name: '资料库', icon: 'receipt', path: '/knowledge-attachment', component: () => import('../views/KnowledgeAttachment/Index.vue') },
        ]
    },
    // {
    //     name: '通知',
    //     expanded: false,
    //     menuItems: [
    //         { permission: '', name: '通知分类', icon: 'stream', path: '/article-category', component: () => import('../views/ArticleCategory/Index.vue') },
    //         { permission: '', name: '通知', icon: 'newspaper', path: '/article', component: () => import('../views/Article/Index.vue') },
    //     ]
    // },
    {
        name: '系统设置',
        expanded: false,
        menuItems: [
            { permission: 'Organization', name: '组织机构', icon: 'sitemap', path: '/organization', component: () => import('../views/Organization/Index.vue') },
            { permission: 'User', name: '用户', icon: 'user', path: '/user', component: () => import('../views/User/Index.vue') },
            { permission: 'Role', name: '角色', icon: 'users', path: '/role', component: () => import('../views/Role/Index.vue') },
            { permission: 'SystemLog', name: '系统日志', icon: 'file-alt', path: '/system-log', component: () => import('../views/SystemLog/Index.vue') },
        ]
    },
];