import store from '../store';

function install(Vue) {
    Vue.directive('permission', {
        inserted(el, binding) {
            let hasPermission = false;
            if (store.state.user) {
                if (binding.value === undefined) {
                    hasPermission = store.state.user != null;
                }
                else {
                    for (const permission of binding.value) {
                        if (store.state.user.permissions.indexOf(permission) > 0) {
                            hasPermission = true;
                            break;
                        }
                    }
                }
            }
            if (!hasPermission) {
                el.parentNode && el.parentNode.removeChild(el);
            }
        }
    });
}

export default { install };