<template>
    <div ref="chart"></div>
</template>

<script>
    import * as echarts from 'echarts';

    export default {
        props: {
            option: { type: Object },
        },
        watch: {
            option(value) {
                if (value) {
                    this.setChartOption(value);
                }
                else {
                    this.clearChart();
                }
            },
        },
        methods: {
            setChartOption(option) {
                let chart = echarts.init(this.$refs.chart, null, { locale: 'ZH' });
                chart.setOption(option);

                chart.off('click', this.clickChart);
                chart.on('click', this.clickChart);
            },
            clearChart() {
                let chart = echarts.init(this.$refs.chart, null, { locale: 'ZH' });
                chart.clear();
            },
            clickChart(event) {
                this.$emit('click', event);
            },
        },
        mounted() {
            if (this.option) {
                this.setChartOption(this.option);
            }
            else {
                this.clearChart();
            }
        },
    };
</script>