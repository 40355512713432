<template>
    <el-upload action="/api/File/Upload" :headers="headers" :accept="accept" :data="data"
        :disabled="disabled||uploading" :multiple="multiple" :show-file-list="false" :before-upload="beforeUpload"
        :on-success="onSuccess" :on-error="onError">
        <slot :file="value">
            <el-input :value="value" readonly :placeholder="placeholder">
                <template #append>
                    <el-button :loading="uploading">选择文件</el-button>
                </template>
            </el-input>
        </slot>
    </el-upload>
</template>

<script>
    export default {
        props: {
            value: { type: String },
            disabled: { type: Boolean, default: false },
            placeholder: { type: String },
            accept: { type: String },
            data: { type: Object },
            multiple: { type: Boolean, default: false },
        },
        data() {
            return {
                headers: { Authorization: 'Bearer ' + localStorage.token },
                uploading: false,
            };
        },
        methods: {
            beforeUpload() {
                this.uploading = true;
                this.$emit('before-upload');
            },
            onSuccess(response) {
                this.uploading = false;
                this.$emit('input', response[0].fileUrl);
                this.$emit('upload-success', response[0]);
            },
            onError(error) {
                this.uploading = false;
                this.$emit('upload-error', error.message);
                this.$message.error(error.message);
            },
        },
    };
</script>